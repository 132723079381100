import React from "react"
import { Image, Col, Layout as AntdLayout, Row, Typography, Space, List } from "antd"
import styled from "@emotion/styled"
import Link from "next/link"
import { linksItems, socialsMenuItems, staticMenuItems } from "../../lib/menu/staticMenuItems"
import moment from "moment"
import { css } from "@emotion/react"
import ContainerFree from "./ContainerFree"
import { regions } from "../../lib/lists/regions"
import { reasons } from "../../lib/lists/reasons"

export const Footer = () => {
  return (
    <>
      <ContainerFree
        css={css`
          margin-top: 60px;
        `}>
        <Space
          wrap={true}
          align="center"
          size={20}
          css={css`
            margin: 20px 0 20px 0;
          `}>
          {[
            ...linksItems,
            ...regions.map((r) => ({
              title: r.title,
              href: `/region/${r.slug}`,
              slug: r.slug
            })),
            ...reasons.map((r) => ({
              title: r.title,
              href: `/proc-prihlasit-vase-dite/${r.slug}`,
              slug: r.slug
            }))
          ].map((item) => (
            <Link key={item.href} href={item.href} title={item.title}>
              {item.title}
            </Link>
          ))}
        </Space>
      </ContainerFree>

      <FooterWithPadding style={{ marginTop: 30 }}>
        <Row gutter={[0, 60]} justify="space-between">
          <Col>
            <Row key="row1" gutter={[0, 0]}>
              <Col>
                <Space direction="vertical" size="middle">
                  <Image alt="vzdelanibudoucnosti_2_black_text" src="/images/vzdelanibudoucnosti_2_black_text.svg" width={180} />

                  <Typography.Title level={2}>Vzdělání budoucnosti, z.s.</Typography.Title>

                  <Typography.Text>
                    Spisová značka: L 72272/MSPH Městský soud v Praze
                    <br />
                    Sídlo: Donská 245/14, Vršovice, 101 00 Praha, Česká republika
                    <br />
                    IČO: 08334463
                  </Typography.Text>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row key="row2" gutter={[0, 0]}>
              <Col>
                <Typography.Title level={4}>Vzdělání budoucnosti</Typography.Title>

                <ListWithoutBorder size="small">
                  {[
                    ...staticMenuItems.map((item) => ({
                      // @ts-ignore
                      title: item?.title || "",
                      // @ts-ignore
                      slug: item?.key || "",
                      // @ts-ignore
                      href: item?.label?.props?.href || ""
                    })),
                    {
                      title: "Prohlášení o cookies",
                      href: "/prohlaseni-o-cookies",
                      slug: "prohlaseni-o-cookies"
                    },
                    {
                      title: "Zpracování osobních údajů",
                      href: "/zpracovani-osobnich-udaju",
                      slug: "zpracovani-osobnich-udaju"
                    },
                    {
                      title: "Zpracování osobních údajů - kurzy pro děti",
                      href: "/zpracovani-osobnich-udaju-krouzky-pro-deti",
                      slug: "zpracovani-osobnich-udaju-krouzky-pro-deti"
                    }
                  ].map((item) => (
                    // @ts-ignore
                    <List.Item key={item.slug}>
                      <Link href={item.href} title={item.title}>
                        {/* // @ts-ignore */}
                        {item?.title || ""}
                      </Link>
                    </List.Item>
                  ))}
                </ListWithoutBorder>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row key="row2" gutter={[0, 0]}>
              <Col>
                <Space direction="vertical" size={40}>
                  <div>
                    <Typography.Title level={4}>Naše projekty</Typography.Title>

                    <ListWithoutBorder size="small">
                      {[
                        {
                          label: "Python GO",
                          href: "https://pythongo.cz/",
                          slug: "python-go"
                        },
                        {
                          label: "Knihovna vzdělání",
                          href: "https://knihovna.vzdelanibudoucnosti.cz/",
                          slug: "knihovna-vzdelani"
                        }
                      ].map((item) => (
                        <List.Item key={item.label}>
                          <Link href={item.href} title={item.label} target="_blank" rel="noopener noreferrer">
                            {item.label}
                          </Link>
                        </List.Item>
                      ))}
                    </ListWithoutBorder>
                  </div>

                  <div>
                    <Typography.Paragraph>
                      Tato služba využívá YouTube API.
                      <br />
                      Používáním naší aplikace souhlasíte
                      <br />s{" "}
                      <Link href="https://www.youtube.com/t/terms" target="_blank" rel="noopener noreferrer">
                        Podmínkami služby YouTube
                      </Link>
                      .
                    </Typography.Paragraph>
                    <Typography.Paragraph>
                      Vaše osobní údaje jsou zpracovávány v souladu
                      <br />s{" "}
                      <Link href="https://policies.google.com/privacy" target="_blank" rel="noopener noreferrer">
                        Zásadami ochrany soukromí Google
                      </Link>
                      .
                    </Typography.Paragraph>
                  </div>
                </Space>
              </Col>
            </Row>
          </Col>
          <Col>
            <Row key="row3" gutter={[0, 0]}>
              <Col>
                <Typography.Title level={3}>Přidejte se k nám</Typography.Title>

                <Space size="middle">
                  {/* @ts-ignore */}
                  {socialsMenuItems.map((item) => item.label)}
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
        <Row key="row3" gutter={[0, 0]} justify="center" style={{ paddingTop: 30, paddingBottom: 30 }}>
          <Col>
            <Typography.Paragraph style={{ marginBottom: 0 }}>
              © Vzdělání budoucnosti {moment().format("YYYY")} v.3.1 <Typography.Text type="secondary">Webové stránky tvoří Pavel Prokeš</Typography.Text>
            </Typography.Paragraph>
          </Col>
        </Row>
      </FooterWithPadding>
    </>
  )
}

const FooterWithPadding = styled(AntdLayout.Footer)`
  padding: 40px 40px 0 40px;
`

const ListWithoutBorder = styled(List)`
  .ant-list-item {
    border-bottom: none;
    padding-left: 0;
  }
`
