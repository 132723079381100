import React from "react"
import styled from "@emotion/styled"
import { theme } from "../../styles/styles"
import { ItemType } from "antd/lib/menu/hooks/useItems"
import Link from "next/link"
import Image from "next/image"

export const Beta = styled.div`
  top: 50%;
  left: 0;
  margin-left: -2px;
  margin-top: 7px;
  position: absolute;
  font-size: 10px;
  color: white;
  background-color: ${theme.colors["primary-color"]};
  line-height: 1;
  padding: 2px;
  transform: rotate(20deg);
  z-index: 9;
`

export const linksItems = [
  {
    title: "Trainee programy",
    href: "/trainee-programy"
  },
  {
    title: "Workshopy",
    href: "/workshopy"
  },
  {
    title: "Jednoduché kroužky pro děti",
    href: "/jednoduche-krouzky-pro-deti"
  },
  {
    title: "Kroužky pro děti Brno a okolí",
    href: "/krouzky-pro-deti-brno-a-okoli"
  },
  {
    title: "Kroužky pro děti Praha",
    href: "/krouzky-pro-deti-praha"
  },
  {
    title: "Kroužky pro děti pro pokročilé",
    href: "/krouzky-pro-deti-pro-pokrocile"
  },
  {
    title: "Kroužky pro nerdy budoucnosti",
    href: "/krouzky-pro-nerdy-budoucnosti"
  },
  {
    title: "Kurzy programování pro děti",
    href: "/kurzy-programovani-pro-deti"
  },
  {
    title: "Letní škola",
    href: "/letni-skola"
  },
  {
    title: "Online kurz pro děti 3D tisk",
    href: "/online-krouzek-pro-deti-3d-tisk"
  },
  {
    title: "Online kurz pro děti Minecraft",
    href: "/online-krouzek-pro-deti-minecraft-education-edition"
  },
  {
    title: "Online kurz pro děti Python",
    href: "/online-krouzek-pro-deti-python"
  },
  {
    title: "Programování pro děti",
    href: "/programovani-pro-deti"
  },
  {
    title: "Programování pro děti online",
    href: "/programovani-pro-deti-online"
  },
  {
    title: "Projektové dny",
    href: "/projektove-dny"
  },
  {
    title: "Napsali o nás",
    href: "/napsali-o-nas"
  },
  {
    title: "Recenze",
    href: "/recenze"
  },
  {
    title: "Staň se naším lektorem",
    href: "/stan-se-nasim-lektorem"
  },
  {
    title: "Ukázkové lekce",
    href: "/ukazkove-lekce"
  }
]

export const staticMenuItems: ItemType[] = [
  {
    label: <Link href="/krouzky-pro-deti">Kurzy pro děti</Link>,
    title: "Kurzy pro děti",
    key: "krouzky-pro-deti"
  },
  {
    label: <Link href="/trainee-programy">Trainee programy</Link>,
    title: "Trainee programy",
    key: "trainee-programy"
  },
  {
    label: <Link href="/recenze">Recenze</Link>,
    title: "Recenze",
    key: "recenze"
  },
  {
    label: <Link href="/napsali-o-nas">Napsali o nás</Link>,
    title: "Napsali o nás",
    key: "napsali-o-nas"
  },
  {
    label: <Link href="/organizace">Organizace</Link>,
    title: "Organizace",
    key: "organizace",
    children: [
      {
        label: <Link href="/poradali-jsme">Pořádali jsme</Link>,
        title: "Pořádali jsme",
        key: "poradali-jsme"
      }
    ]
  },
  {
    label: <Link href="/blog">Blog</Link>,
    title: "Blog",
    key: "blog"
  },
  {
    label: <Link href="/kontakt">Kontakt</Link>,
    title: "Kontakt",
    key: "kontakt"
  }
]

export const socialsMenuItems: ItemType[] = [
  {
    label: (
      <Link href="https://www.facebook.com/vzdelanibudoucnosti" title="Facebook" target="_blank" rel="noopener noreferrer">
        <Image
          alt="Facebook_Logo_Primary"
          src="/images/logos/Facebook_Logo_Primary.png"
          width={0}
          height={0}
          sizes="100vw"
          style={{ width: "auto", height: "22px" }}
        />
      </Link>
    ),
    title: "Facebook",
    key: "facebook"
  },
  {
    label: (
      <Link href="https://www.youtube.com/channel/UCo_bRS8x2_oYv78lEj52lUw" title="Youtube Nerdi budoucnosti" target="_blank" rel="noopener noreferrer">
        <Image alt="yt_icon_rgb" src="/images/logos/yt_icon_rgb.png" width={0} height={0} sizes="100vw" style={{ width: "auto", height: "22px" }} />
      </Link>
    ),
    title: "Youtube Nerdi budoucnosti",
    key: "youtube-nerdi-budoucnosti"
  },
  {
    label: (
      <Link href="https://g.page/r/CfOxvTup-d7hEAE" title="Google business" target="_blank" rel="noopener noreferrer">
        <Image alt="google_logo" src="/images/logos/google_logo.png" width={0} height={0} sizes="100vw" style={{ width: "auto", height: "22px" }} />
      </Link>
    ),
    title: "Google business",
    key: "google-business"
  },
  {
    label: (
      <Link href="https://www.instagram.com/nerdi_budoucnosti/" title="Instagram" target="_blank" rel="noopener noreferrer">
        <Image alt="instagram_logo" src="/images/logos/instagram_logo.png" width={0} height={0} sizes="100vw" style={{ width: "auto", height: "22px" }} />
      </Link>
    ),
    title: "Instagram",
    key: "instagram"
  }
]
